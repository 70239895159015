var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column", attrs: { id: "create-company-page" } },
    [
      _c("div", { attrs: { id: "create-company-div" } }, [
        _c("div", { staticClass: "container d-flex h-100" }, [
          _c(
            "div",
            {
              staticClass: "row justify-content-center align-self-center w-100",
            },
            [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm._t("body", function () {
                    return [_c(_vm.currentSlide, { tag: "component" })]
                  }),
                ],
                2
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }